import React from "react"

interface SessionStateController<TValue>{
  get: () => TValue
  set: (value: TValue) => void
}

const createBooleanValue = (key: string): SessionStateController<boolean> => ({
  get: () => window.sessionStorage.getItem(key) === '1',
  set: (value: boolean) => window.sessionStorage.setItem(key, value ? '1' : '0')
})

const createNullableBooleanValue = (key: string): SessionStateController<boolean | null> => ({
  get: () => {
    const val = window.sessionStorage.getItem(key);
    if (val === null) return null;
    return val === '1'
  },
  set: (value: boolean | null) => {
    if (value === null) {
      window.sessionStorage.removeItem(key);
    }
    else {
      window.sessionStorage.setItem(key, value ? '1' : '0')
    }
  }
});

// const createStringOptionsValue = <TValue>(key: string, default: TValue): SessionStateController<TValue> => ({
//   get: () => window.sessionStorage.getItem(key) ,
//   set: (value: boolean) => window.sessionStorage.setItem(key, value ? '1' : '0')
// })


interface SetlistTransposes {
  [setlistSongId: number]: number
}


export const sessionState = {
  liveSyncEnabled: createNullableBooleanValue('LIVE_SYNC_ENABLED'),
  liveSyncSidebarDocked: createBooleanValue('LIVE_SYNC_SIDEBAR_DOCKED'),
  songListLayout: {
    get: (): 'rows' | 'cols' => window.sessionStorage.getItem('SONG_LIST_LAYOUT') === 'rows' ? 'rows' : 'cols',
    set: (value: 'rows' | 'cols') => window.sessionStorage.setItem('SONG_LIST_LAYOUT', value),
  },
  mySetlistTransposes: {
    get: (): SetlistTransposes => JSON.parse(window.sessionStorage.getItem('SETLIST_TRANSPOSES') ?? '{}'),
    set: (value: SetlistTransposes) => window.sessionStorage.setItem('SETLIST_TRANSPOSES', JSON.stringify(value)),
  },
}


export const useSessionState = <TValue extends unknown>(ctl: SessionStateController<TValue>): [TValue, (value: TValue) => void] => {
  const [state, setState] = React.useState(ctl.get())
  return [
    state, 
    (value: TValue) => {
      setState(value);
      ctl.set(value);
    }
  ]
};
