import React from 'react'
import { Loading } from './Loading';
import logo from '../svg/songlib-logo.svg';


interface Props {
  children?: React.ReactNode
  errorMessage?: string
  title?: React.ReactNode
}


export const AppError = (props: Props) => {
  return (
    <Loading>
      <div style={{maxWidth: '100px'}} className='mx-auto mb-3'>
        <img src={logo} className='img-fluid' style={{filter: 'grayscale(1) brightness(70%)'}}/>
      </div>
      <div className='bg-light border p-3 pb-0 mx-auto' style={{maxWidth: '500px'}}>
        <h5>{props.title ?? 'So um yeah... something went wrong...'}</h5>
        {props.errorMessage && <p className='text-muted small'>{props.errorMessage}</p>}
        <p>{props.children}</p>
        <div>
          <button onClick={() => window.location.reload()} className='btn btn-secondary me-3 mb-3'>Retry loading</button>
          <a href="https://songlib.com/" className='btn btn-outline-secondary mb-3'>Go to songlib.com</a>
        </div>
      </div>
    </Loading>
  );
};


