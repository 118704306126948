import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { IntrospectionQuery } from 'graphql';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { createClient, dedupExchange, Exchange, subscriptionExchange } from 'urql';
import { SetlistSongFragmentDoc, UpdateSetlistSongMutationVariables } from './generated/graphql';
import schema from './generated/schema.json';
import { settings } from './settings';

// const storage = makeDefaultStorage({
//   idbName: 'graphcache-v3', // The name of the IndexedDB database
//   maxAge: 7, // The maximum age of the persisted data in days
// });


const subscriptionClient = new SubscriptionClient(settings.graphqlWsURL, { reconnect: true });

export const client = createClient({
  url: settings.graphqlURL,
  fetchOptions: () => ({
    credentials: settings.graphqlNoCredentials ? 'omit' : 'include',
    mode: 'cors',
  }),
  exchanges: [
    devtoolsExchange, 
    dedupExchange, 
    cacheExchange({
      schema: schema as any as IntrospectionQuery,
      // storage,
      keys: {
        // THere is only every one of these, since we are single user system
        // so hardcode an ID.  Not using an int to avoid confusing for user
        Me: data => "me",
        // These types are just grouped properties of their parent types
        // so we are explicitly not giving them a key
        LibraryImage: () => null,
        TransposeInfo: data => null,
        Image: () => null,
        UserSongInfo: () => null,
        SetlistLink: (obj) => obj.key as string,
      },

      resolvers: {
        Query: {
          song: (_, args) => ({__typename: 'Song', id: args.id}),
          setlist: (_, args) => ({__typename: 'Setlist', id: args.id}),
        },
      },

      optimistic: {
        updateSetlistSong: (variables, cache, info) => {

          const {data} = variables as UpdateSetlistSongMutationVariables

          // Read data from cache
          const setlistsong = cache.readFragment(SetlistSongFragmentDoc, {__typename: "SetlistSong", id: data.setlistSongId}) as any
          
          // Update it with variable data
          if (typeof data.notes === "string") setlistsong.notes = data.notes
          if (typeof data.transpose?.semitones === "number") setlistsong.transpose.semitones = data.transpose.semitones
          if (typeof data.transpose?.style === "string") setlistsong.transpose.style = data.transpose.style
          
          // Return the optimistic update
          return { setlistsong, __typename:"UpdateSetlistSongPayload" }
        },
        // deleteSetlistSong: (variables, cache, info) => {
        //   console.log('humphrey', variables, cache, info)
        //   return null
        //   // return {
        //   //   __typename: "SetlistSong",

        //   // }
        // }
      }
    }), 
    // fetchExchange,
    multipartFetchExchange as Exchange,  // Hack, since there was a type error i didn't understand

    // Add in subscriptions
    subscriptionExchange({
      // forwardSubscription: operation => ({
      //   subscribe: (sink) => ({
      //     unsubscribe: wsClient.subscribe(operation, sink),
      //   }),
      // }),
      forwardSubscription: operation => subscriptionClient.request(operation)
    }),
  ],
});
