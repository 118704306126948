import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { Provider as URQLProvider } from 'urql';
import { QueryParamProvider } from 'use-query-params';
import { client } from '../urql';
import { App } from './App';


export const AppWrapper = (props: {children: React.ReactNode}) => (
  <URQLProvider value={client}>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        {props.children}
      </QueryParamProvider>
    </BrowserRouter>
  </URQLProvider>
)


export const WrappedApp = () => (
  <AppWrapper>
    <App/>
  </AppWrapper>
)