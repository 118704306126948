import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useMedia } from 'react-media';
import { Link, useHistory } from 'react-router-dom';
import { BooleanParam, NumberParam, QueryParamConfig, StringParam, useQueryParams } from 'use-query-params';
import { AppContext } from '../app/AppContext';
import { FullPage, FullPageContext } from '../chrome/FullPage';
import { H1, H1b } from '../chrome/H1';
import { Loading } from '../chrome/Loading';
import { Modal, useModal } from '../chrome/Modal';
import { TransposeStyle, useChangeSongKeyMutation, useRecordSongTransposeMutation, useSongQuery } from '../generated/graphql';
import { icons } from '../icons';
import { LibraryContext } from '../library/LibraryContext';
import { PushLiveBtn } from '../livesync/PushLiveBtn';
import { GRID_MEDIA_QUERIES } from '../media';
import { settings } from '../settings';
import { SongsListGroup } from '../songs/SongsListGroup';
import { useSongs } from '../songs/useSongs';
import { DisplayKey, KeySelectProps } from '../transpose/KeySelect';
import { KeySelectSimple } from '../transpose/KeySelectSimple';
import { KeySelectUpDown } from '../transpose/KeySelectUpDown';
import { getSonglibTransposeQuery } from '../transpose/transpose';
import { truncateChars } from '../utils/text';
import { ChordChartAutoSizing } from './ChordChartAutoSizing';
import { DuplicateSongModal } from './DuplicateSongModal';
import { SongMetaTable } from './SongMetaTable';
import cs from 'classnames';


interface Props {
  id: number
  readOnly?: boolean
}


// Custom handler for QueryParam that only allows FLATS or SHARPS
// Only allow flats or sharps
const TransposeStyleParm: QueryParamConfig<TransposeStyle | null | undefined> = {
  ...StringParam,
  decode: val => {
    if (val === TransposeStyle.Flats || val === TransposeStyle.Sharps) {
      return val
    }
    return null
  }
}


export const SongPage = (props: Props) => {

  const lib = React.useContext(LibraryContext)
  const app = React.useContext(AppContext)

  // Hooks
  const [result] = useSongQuery({
    variables: {id: props.id}, 
    requestPolicy: "cache-only",
  });
  
  const song = result.data?.song;
  const media = useMedia({queries: GRID_MEDIA_QUERIES})
  const history = useHistory()
  const [, changeSongKey] = useChangeSongKeyMutation();
  const [queryParams, setQueryParams] = useQueryParams({
    semitones: NumberParam,
    style: TransposeStyleParm,
    duplicateModal: BooleanParam,
    // expanded: BooleanParam,
    edit: BooleanParam,
  })

  const toggleEdit = () => setQueryParams({...queryParams, edit: !queryParams.edit}, 'replace');

  // Key
  const [recordTransposeResult, executeRecordTranspose] = useRecordSongTransposeMutation();

  // If get params specified, use them, otherwise, use the last key the user used.
  const transpose: DisplayKey = (queryParams.semitones || null) !== null ? 
    {
      semitones: queryParams.semitones || 0,
      style: queryParams.style || TransposeStyle.Sharps,
    } 
  :
    song?.forMe?.lastTranspose || {
      semitones: 0,
      style: TransposeStyle.Sharps,
    };

  const handleKeyChange = (value: DisplayKey) => {
    setQueryParams({
      ...queryParams, 
      semitones: (value.semitones ? value.semitones : undefined),
      style: value.style,
    }, 'replace');
    executeRecordTranspose({
      data: {
        songId: props.id, 
        transpose: {
          semitones: value.semitones || 0, 
          style: value.style
        },
      }
    })
  }

  const songDetailsModal = useModal()
  const duplicateModal = useModal();

  const { sidebar } = React.useContext(FullPageContext);

  const getSongContent = () => {

    if (song) {

      const keySelectProps: KeySelectProps = {
        onChange: handleKeyChange,
        semitones: transpose?.semitones || 0,
        style: transpose?.style || TransposeStyle.Sharps,
        originalKey: song.key ?? '',
        align: "right",
        // compact={isSm}
        btnClass: "btn btn-light px-2 py-1",
        onSave: async () => {
          await changeSongKey({data: {
            songId: song.id,
            semitones: transpose?.semitones || 0,
            style: transpose?.style || TransposeStyle.Sharps,
            fromKey: song.key,
          }})
          handleKeyChange({semitones: 0, style: transpose?.style || TransposeStyle.Sharps})
        },
      };

      return {

        actions: (
          <div className="d-flex">
            {/* {media.mdGTE &&
              <button 
              onClick={toggleSidebar} 
              className={cs("btn btn-plain nav-link", !sidebarVisible && 'active')}>
              <LeftSidebarIcon title="Toggle visibility of the sidebar"/>
              </button>
            } */}
            {/* {props.toolbarInfo?.right && <div className="mx-2 d-none d-sm-block" style={{borderRight: '1px solid #555'}}/>} */}
            <PushLiveBtn data={{songId: song.id, transpose: transpose ? {semitones: transpose.semitones, style:transpose.style} : undefined}} className="btn btn-light px-2 py-1 me-3">Push live</PushLiveBtn>
            
            <>{media.mdGTE ? <KeySelectUpDown {...keySelectProps}/> : <KeySelectSimple {...keySelectProps}/>}</>
            <Link to={lib.buildLink.editsong(props.id)} className='btn btn-light px-2 py-1 ms-3 me-3'>Edit</Link>
            {/* <div className="mx-2 d-none d-sm-block" style={{borderRight: '1px solid #555'}}/> */}
            <div className="nav-item dropdown">
              <button className="btn btn-light px-3 py-1" id="navbarDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <FontAwesomeIcon icon={icons.more}/><span className='d-none d-md-inline ms-2'>More</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-iconed" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#edit" data-bs-toggle="modal" data-bs-target={`#${songDetailsModal.htmlId}`}><FontAwesomeIcon icon={icons.songDetails}/>Song details...</a>
                <a className="dropdown-item" href={settings.serverURL + song.asPdfLink + getSonglibTransposeQuery(transpose)}><FontAwesomeIcon icon={icons.pdf}/>Download song as PDF</a>
                <a className={cs('dropdown-item', !song.ccli && 'disabled')} href={song.ccli ? `https://songselect.ccli.com/songs/${song.ccli}`: '#'} target="_blank"><FontAwesomeIcon icon={faExternalLink}/>View on CCLI Song Select</a>

              <div className="dropdown-divider"></div>
                {/* {!props.readOnly && <Link className="dropdown-item" to={lib.buildLink.editsong(props.id)}><FontAwesomeIcon icon={icons.edit}/>Edit</Link>} */}
                {!props.readOnly && <a className="dropdown-item" href="#edit"><FontAwesomeIcon icon={icons.delete}/>Delete...</a>}
                <div className="dropdown-divider"></div>
                {<a className="dropdown-item" href="#edit" data-bs-toggle="modal" data-bs-target={`#${duplicateModal.htmlId}`}><FontAwesomeIcon icon={icons.duplicateSong}/>Copy or duplicate song...</a>}
              </div>
            </div>
            
            {/* {props.minimise &&
              <li className="nav-item active">
              <Link to={props.minimise} className="nav-link px-3" title="Minimise"><FontAwesomeIcon icon={icons.minimise}/></Link>
              </li>
            } */}
            {/* // </ul> */}
          </div>
        ),

        title: (
          <H1>
            <span className="me-2">{song.title}</span>
            {song.author && <H1b>{truncateChars(song.author, 50)}</H1b>}
          </H1>
        ),

        children: (
          <>
            <ChordChartAutoSizing
              plainText={song?.chordChart || ''}
              sizeSource={sidebar.visible}
              transpose={transpose}
            />

            <Modal ref={songDetailsModal.ref} id={songDetailsModal.htmlId} title="Song details" size="lg" flush scrollable>
              <SongMetaTable song={song} transpose={transpose}/>
            </Modal>
      
            <Modal id={duplicateModal.htmlId} ref={duplicateModal.ref} title="Save a duplicate of this song into" scrollable flush>
              <DuplicateSongModal
                // visible={duplicate || false}
                songId={song.id}
                onDuplicate={r => {
                  // setDuplicate(false, 'replace')
                  history.push(r.url)
                }}
                hide={() => {
                  duplicateModal.hide();
                }}
              />
            </Modal>
          </>
        )
      };
    }
  }


  const hook = useSongs({selectedSongId: props.id, slim: true});
  const p = hook.data;

  const songContent = getSongContent();

  
  // if (!songContent) {
  //   return (result.fetching ? <Loading>Loading chord chart</Loading> : <>Error</>)
  // }

  return (
    <FullPage
      title={songContent?.title}
      actions={songContent?.actions}
      sidebar={{
        title: "Songs",
        children: p ? <SongsListGroup {...p} sidebar/> : <Loading/>,
        actions: null,
      }}
      back={{ to: lib.buildLink.songs() }}
    >
      {songContent ? 
          songContent.children
        :
          (
            result.fetching ? 
              <Loading>Loading chord chart</Loading> 
            : 
              <>Error</>
          )
      }
    </FullPage>
  )
};