import { faGuitar, faPianoKeyboard } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';


interface Props {
  value: 'capo' | 'semitones'
  onChange: (value: 'capo' | 'semitones') => void
  w100? :boolean
}


export const InstrumentToggleBtn = (props: Props) => {
  
  return (
    <div className={cs('btn-group', props.w100 && 'w-100')}>
      <button 
          className={cs("btn btn-light px-2 py-1", props.value === 'capo' && 'active')} 
          type="button" 
          onClick={e => {
            e.stopPropagation();
            props.onChange('capo');
          }} 
          title="Transpose using a capo"
      >
        Capo
        {/* <FontAwesomeIcon icon={faGuitar}/> */}
      </button>
      <button 
          className={cs("btn btn-light px-2 py-1", props.value === 'semitones' && 'active')} 
          type="button" 
          onClick={e => {
            e.stopPropagation();
            props.onChange('semitones');
          }}
          title="Transpose by semitones"
      >
        Semitones
        {/* <FontAwesomeIcon icon={faPianoKeyboard}/> */}
      </button>
    </div>
  );
};
