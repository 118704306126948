import React from 'react';
import cs from 'classnames';
import styles from './H1.module.scss';


export const H1 = ({className, ...passThruProps}: React.HTMLAttributes<HTMLHeadingElement>) => (
  <h1 
    {...passThruProps} 
    className={cs("py-1 my-0 d-flex flex-wrap align-items-center", styles.H1, className)}
  />
);

export const H1b = ({className, ...passThruProps}: React.HTMLAttributes<HTMLElement>) => (
  <small 
    {...passThruProps} 
    className={cs("lead text-muted", className)}
  />
);
