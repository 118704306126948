import React from 'react';


interface Props {
  msInterval: number
  children: React.ReactNode
}


export const IntervalRenderer = (props: Props) => {
  const [n, setN] = React.useState(new Date());

  React.useEffect(() => {
    const timer = window.setInterval(() => {
        setN(new Date());
      }, props.msInterval
    )
    return () => { window.clearInterval(timer) }
  }, [])

  return <>{props.children}</>;
};