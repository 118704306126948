import CodeMirror from 'codemirror'


CodeMirror.defineMode("chordchart", function() {

  function eatHeading(stream) {
      var x = stream.next();
      if (x === '[' || x === ']') {
          return "heading-bracket";
      }
      return "heading";
  }

  return {
    startState: function() {
        return {
            lineType: ''
        };
    },
    token: function(stream, state) {

      if (stream.sol()) {

        var mHeading = stream.match(/^ *\[.*\] *$/g, false);
        var mLyrics = stream.match(/^ +[^ ]+/g, false);

        if (mHeading) {
          state.lineType = 'heading';
          return eatHeading(stream);
        }
        if (mLyrics) {
          state.lineType = 'lyrics';
          stream.next();
          return "lyrics-space";
        }
        else if (stream.peek() === ".") {
          state.lineType = 'chords';
          stream.next();
          return "chords-dot";
        }

      }
      else if (state.lineType === 'chords') {
        stream.skipToEnd();    // Rest of line is string
        return "chords";
      }
      else if (state.lineType === 'heading') {
        return eatHeading(stream);
      }
      else if (state.lineType === 'lyrics') {
        stream.skipToEnd();    // Rest of line is string
        return "lyrics";
      }

      stream.skipToEnd();    // Rest of line is string
      return null;

    }
  };
});