import { useElementId } from "../hooks/elementIds";
import cs from 'classnames';


interface Props {
  value: boolean
  onChange: (newValue: boolean) => void
  children?: React.ReactNode
  bold?: boolean
  flush?: boolean
}


export const ToggleSwitch = (props: Props) => {
  const elId = useElementId('toggle-switch')
  return (
    <div className={cs("form-check form-switch", props.flush && 'mb-0')}>
      <input className="form-check-input" id={elId} type="checkbox" role="switch" 
        checked={props.value} 
        onChange={e => props.onChange(e.target.checked)}/>
      <label className={cs("form-check-label", props.bold && 'fw-bold')} htmlFor={elId}>{props.children}</label>
    </div>
  );
}
