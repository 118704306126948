import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { useDates } from '../dates';
import { SetlistLinkFragment } from '../generated/graphql';
import { LiveSyncContextData } from '../livesync/LiveSyncContext';
import { settings } from '../settings';


interface Props {
  setlistLink: SetlistLinkFragment
  liveSync: LiveSyncContextData
}


export const OffCanvasSetlistMeta = (props: Props) => {
  const {liveSync, setlistLink} = props;
  const {setlist} = setlistLink;
  const dates = useDates();
  return (
    <>
      <div className='d-flex mt-n3 pb-3 px-3 '>
        {/* {setlistLink.libraryImage && <img 
          src={setlistLink.libraryImage.url} 
          width={setlistLink.libraryImage.width ?? undefined}
          height={setlistLink.libraryImage.height ?? undefined}
          className='me-2 my-1'
          style={{width: '40px', height: '40px'}}
        />} */}
        <div className=''>
          <div className='d-flex align-items-center'>
            {setlistLink.libraryImage && <img 
              src={setlistLink.libraryImage.url} 
              width={setlistLink.libraryImage.width ?? undefined}
              height={setlistLink.libraryImage.height ?? undefined}
              className='me-2 my-1'
              style={{width: '20px', height: '20px'}}
            />}
            <div><b>{setlistLink.libraryTitle}</b></div>
          </div>
          <div className='d-flex align-items-center'>
            <small className="me-3">{dates.formatAsDayOfWeek(setlist.date)} {dates.formatAsUser(setlist.date)}</small>
            <small className="me-3">{setlist.songs.length} Songs</small>
            <a className='btn btn-light btn-sm' href={settings.serverURL + props.setlistLink.exportPdfLink} target="_blank">
              <FontAwesomeIcon icon={faDownload} className='me-2'/>PDF
            </a>
            {/* {setlist.leader?.fullName && <small>Lead by {setlist.leader?.fullName}</small>} */}
          </div>
          <div className='small text-muted fst-italic' style={{whiteSpace: 'break-spaces'}}>
            Notes: <span className=''>{setlist.notes}</span>
          </div>
        </div>
      </div>
      {/* <div className='px-3 pb-3 mt-n3 d-flex'>
        {setlistLink.libraryTitle}
        {setlistLink.libraryImage && <img src={setlistLink.libraryImage.url}/>}
      </div> */}
      {/* <div className='px-3 pb-3 mt-n3'>
        <small className="me-3">{dates.formatAsUser(setlist.date)}</small>
        <small className="me-3">{setlist.songs.length} Songs</small>
        {setlist.leader?.fullName && <small>Lead by {setlist.leader?.fullName}</small>}
      </div> */}
      {/* <div className='mx-3 mb-3 d-flex align-items-center justify-content-between'>
        <ToggleSwitch value={liveSync.enabled} onChange={liveSync.setEnabled}>Live Sync</ToggleSwitch> */}
        {/* <ToggleSwitch value={hideChords} onChange={setHideChords}>Hide Chords</ToggleSwitch> */}
        {/* <a className='btn btn-light' href={settings.serverURL + props.setlistLink.exportPdfLink} target="_blank">
          <FontAwesomeIcon icon={faDownload} className='me-2'/>PDF
        </a> */}
      {/* </div> */}
      <div className='border-bottom'/>
    </>
  );
};