import { flushHeaderBtnClass, flushHeaderTxtClass } from '../chrome/FullPageContentPane';
import { SetlistSongFragment } from '../generated/graphql';
import { localState, useLocalState } from '../storage/localState';
import { CapoDropdown } from './CapoDropdown';
import cs from 'classnames';


interface Props {
  setlistSong: SetlistSongFragment
  semitones: number
  onTranspose: (semitones: number) => void
}


export const  SetlistListSongActions = (props: Props) => {
  const {semitones, setlistSong} = props;
  const [transposeType, setTransposeType] = useLocalState(localState.transposeType);
  
  return (
    <>
      {setlistSong.transpose.toKey && 
        <span className={cs(flushHeaderTxtClass, 'd-none d-md-inline')}>
          Setlist Key: <b>{setlistSong.transpose.toKey}</b>
        </span>
      }
      <CapoDropdown
        setlistKey={setlistSong.transpose.toKey || null} 
        semitones={semitones} 
        onChange={value => props.onTranspose(value)}
        type={transposeType}
        onTypeChange={setTransposeType}
        className={flushHeaderBtnClass}
        emptyMsg=''
      >
        <span className='d-none d-md-inline' >
          My Key{semitones !== 0 && ': '}
        </span>
      </CapoDropdown>
    </>
  );
};
