
export const setlistAppRoutes = {
  setlistLink:     {
    pattern: "/:setlistkey([a-zA-Z0-9]+)/",
    build: (key: string) => `/${key}/`,
  },
  setlistSong: {
    pattern: "/:setlistkey([a-zA-Z0-9]+)/:setlistsong(\\d+)/",
    build: (key: string, setlistSong: number) => `/${key}/${setlistSong}/`,
  },
}
