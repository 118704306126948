import cs from 'classnames';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { useElementId } from '../hooks/elementIds';

// Modals are rendered in a different #modal-root so that they are not hidden
export const modalRoot = document.getElementById('modal-root') as HTMLDivElement
if (!modalRoot) throw new Error("COuld not find #modal-root to render modal in")

// Simple component (copied from the docs) that renders a component in a different Portal
export class ModalContainer extends React.Component<{children: ReactNode, id?: string}> {
  el: HTMLDivElement

  constructor(props: {children: ReactNode}) {
    super(props);
    this.el = document.createElement('div');
    if (this.props.id) {
      this.el.setAttribute('data-modal-id', this.props.id);
    }
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);  
  }
}

export interface ModalProps {
  id: string
  children: React.ReactNode
  footer?: React.ReactNode
  title?: React.ReactNode
  size?: 'sm' | 'lg' | 'xl'
  scrollable?: boolean
  flush?: boolean
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>((props, ref) => (
  <ModalContainer id={props.id}>
    <div ref={ref} className="modal fade" id={props.id} tabIndex={-1} aria-labelledby={`${props.id}Label`} aria-hidden="true">
      <div className={cs("modal-dialog", props.scrollable && "modal-dialog-scrollable", props.size && `modal-${props.size}`)}>
        <form className="modal-content" onSubmit={props.onSubmit}>
          <div className="modal-header">
            {props.title && <h5 className="modal-title" id={`${props.id}Label`}>{props.title}</h5>}
            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
          </div>
          <div className={cs("modal-body", props.flush && 'p-0')}>
            {props.children}
          </div>
          {props.footer && <div className="modal-footer">{props.footer}</div>}
        </form>
      </div>
    </div>
  </ModalContainer>
));


declare const $: any;

interface UseModalConfg {
  name?: string
  visible?: boolean
  // props: Props
  onHide?: () => void
}

export const useModal = ({visible, onHide, name}: UseModalConfg = {}) => {
  
  const htmlId = useElementId('Modal' + (name ?? ''))
  const ref = React.useRef<HTMLDivElement>(null);
  const [visibleState, setVisibleState] = React.useState(visible || false)
  
  const getModal = () => {
    if (ref.current)
      return $(ref.current);
    return null;
  }

  const result = {
    // component: <Modal ref={ref} {...props}/>,
    ref,
    hide: () => {
      const m = getModal();
      if (!m) return;

      // Returning a promise so that consumers have the option of 
      // awaiting the result and not doing something until after the modal is hidden
      const promise = new Promise<void>((resolve) => {

        // Listen for hidden
        const handleHidden = () => {
          ref.current?.removeEventListener('hidden.bs.modal', handleHidden);
          resolve();
        }
        ref.current?.addEventListener('hidden.bs.modal', handleHidden)

        getModal()?.modal('hide');
        setVisibleState(false);
      })
      return promise;
    },
    // show: () => getModal()?.show(),
    show: () => {
      // const 
      const m = getModal();
      m?.modal('show');
      setVisibleState(true);
    },
    toggle: () => getModal()?.modal('toggle'),
    htmlId,
    visible: visibleState,
  }

  // Auto show the modal based on the url params
  React.useEffect(() => {
    if (visible) 
      getModal()?.modal('show');
    return () => getModal()?.modal('hide')
  }, [visible])

  // Trigger the onHide method if modal hidden 
  React.useEffect(() => {
    const hideHandler = () => {
      if (onHide) onHide();
      setVisibleState(false);
    }
    const showHandler = () => {
      setVisibleState(true);
    }
    getModal()?.on('hidden.bs.modal', hideHandler);
    getModal()?.on('show.bs.modal', showHandler);
    return () => {
      getModal()?.off('hidden.bs.modal', hideHandler);
      getModal()?.off('show.bs.modal', showHandler);
    }
  }, [onHide])

  return result
}

export type UseModal = ReturnType<typeof useModal>;
