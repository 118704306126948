import React from 'react';
import { CurrentSongFragment, useCurrentSongForLibrarySubscription, useCurrentSongForSetlistSubscription } from '../generated/graphql';
import { sessionState, useSessionState } from '../storage/sessionState';


export interface LiveSyncContextData {
  currentSong: CurrentSongFragment | null
  enabled: boolean
  setEnabled: (value: boolean) => void
  sidebar: boolean
  setSidebar: (value: boolean) => void
  libraryId: number | null
  setlistKey: string | null
  active: boolean
}


export const LiveSyncContext = React.createContext<LiveSyncContextData>({
  libraryId: null,
  setlistKey: null,
  currentSong: null,
  enabled: false,
  sidebar: false,
  setEnabled: () => {},
  setSidebar: () => {},
  active: false,
});


export const useLiveSyncLibraryContextState = (config: {libraryId: number | null}): LiveSyncContextData => {
  const pause = config.libraryId === null
  const [result] = useCurrentSongForLibrarySubscription({
    variables: {libraryId: config.libraryId ?? -1},
    pause,
  });
  const [enabled, setEnabled] = useSessionState(sessionState.liveSyncEnabled);
  const [sidebar, setSidebar] = useSessionState(sessionState.liveSyncSidebarDocked);
  return {
    libraryId: config.libraryId,
    setlistKey: null,
    currentSong: result.data?.currentSongForLibrary ?? null,
    enabled: enabled ?? false,
    setEnabled,
    sidebar, setSidebar,
    active: !pause,
  }
}


interface Config {
  setlistKey: string | null
  startEnabled: boolean
  allowed: boolean
}


export const useLiveSyncSetlistContextState = (config: Config): LiveSyncContextData => {
  const pause = !config.allowed || config.setlistKey === null;
  const [result] = useCurrentSongForSetlistSubscription({
    variables: {setlistKey: config.setlistKey ?? null},
    pause,
  });
  const [enabled, setEnabled] = useSessionState(sessionState.liveSyncEnabled);
  const [sidebar, setSidebar] = useSessionState(sessionState.liveSyncSidebarDocked);
  return {
    libraryId: null,
    setlistKey: config.setlistKey,
    currentSong: result.data?.currentSongForSetlist ?? null,
    // The setlinklink can be configured to start enabled
    enabled : enabled === null ? config.startEnabled : enabled,
    setEnabled,
    sidebar, setSidebar,
    active: !pause,
  }
}


export const useLiveSyncContext = () => React.useContext(LiveSyncContext);
