import { faThumbTack as faThumbTackRegular } from '@fortawesome/pro-regular-svg-icons';
import { faThumbTack as faThumbTackActive } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { useMedia } from 'react-media';
import { ModalContainer } from '../chrome/Modal';
import { useElementId } from '../hooks/elementIds';
import { GRID_MEDIA_QUERIES } from '../media';
import { OffCanvasBackdrop } from './OffCanvasBackdrop';


interface ScaffoldInfo {
  show: () => void;
  hide: () => void;
  togglePinned: () => void;
  open: false | "showing" | "show" | "hiding";
  toggleOpen: () => void
  pinned: false | "pinning" | "pinned" | "unpinning";
  offCanvasId: string;
}

interface Props {
  transitionDuration?: number
  offCanvasTitle?: React.ReactNode
  offCanvasHeader?: React.ReactNode
  offCanvasContent?: (info: ScaffoldInfo) => React.ReactNode
  className?: string
  children?: (info: ScaffoldInfo) => React.ReactNode
  placement?: 'start' | 'end'
  ref?: React.LegacyRef<HTMLDivElement>
  initiallyOpen?: boolean
  closeDisabled?: boolean
  pinningDisabled?: boolean
}

const offCanvasWidth = '400px';

export const PinnableOffCanvas = (props: Props) => {
  const gteLg = useMedia({query: GRID_MEDIA_QUERIES.lgGTE});
  const {
    transitionDuration = 300,
    placement = 'start',
  } = props;
  const [open, setOpen] = React.useState<false | 'showing' | 'show' | 'hiding'>(props.initiallyOpen ? 'show' : false);
  const [_pinned, setPinned] = React.useState<false | 'pinning' | 'pinned' | 'unpinning'>(false);
  const pinned = (gteLg && !props.pinningDisabled) ? _pinned : false;
  const pinnedActive = !(pinned === false || pinned === 'unpinning');
  const offCanvasId = useElementId('offcanvas');


  const show = () => {
    if (!open) {
      setOpen('showing')
      setTimeout(() => setOpen('show'), transitionDuration)
    }
  };
  const hide = () => {
    if (open === 'show') {
      setOpen('hiding');
      setTimeout(() => {
        setOpen(false); 
      }, transitionDuration)
    }
  };
  const togglePinned = () => {
    if (open === 'show') {
      if (pinned === false) {
        setPinned('pinning');
        setTimeout(() => setPinned('pinned'), transitionDuration)
      }
      else if (pinned === 'pinned') {
        setPinned('unpinning');
        if (!props.closeDisabled) {
          hide();
        }
        setTimeout(() => setPinned(false), transitionDuration)
      }
    }
  }
  const toggleOpen = () => {
    show();
    hide();
  }

  const scaffoldInfo: ScaffoldInfo = {show, hide, togglePinned, open, pinned, offCanvasId, toggleOpen};

  const padding = (open === 'show' || open === 'showing') && (pinned === 'pinned' || pinned === 'pinning') ? offCanvasWidth : '0';

  return (
    <div className={props.className} ref={props.ref} style={{
      transitionProperty: 'padding-left,padding-right',
      transitionDuration: '0.3s',
      ...(placement === 'start' ? {paddingLeft: padding} : {paddingRight: padding})
    }}>

      <ModalContainer>

        <div className={cs(`offcanvas offcanvas-${placement}`, open ?? '')} tabIndex={-1} id={offCanvasId} aria-labelledby={`${offCanvasId}Label`}>
          {props.offCanvasHeader ?? 
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id={`${offCanvasId}Label`}>{props.offCanvasTitle}</h5>
              {gteLg && !props.pinningDisabled &&
                <button 
                  type="button" 
                  className={cs("btn-close ms-auto me-2")}
                  style={{backgroundImage: 'none', lineHeight: '1'}}
                  aria-label="Pin to side"
                  onClick={togglePinned}
                >
                  <FontAwesomeIcon icon={pinnedActive ? faThumbTackActive : faThumbTackRegular} className='mt-n2'/>
                </button>
              }
              <button type="button" className="btn-close" onClick={hide} aria-label="Close" disabled={props.closeDisabled}></button>
            </div>
          }
          {props.offCanvasContent && props.offCanvasContent(scaffoldInfo)}
        </div>

        {(open && (pinned !== 'pinned')) && 
          <OffCanvasBackdrop onClick={() => {if (!props.closeDisabled) {hide()}}} hiding={open === 'hiding' || pinned === 'pinning'} initiallyShown={props.initiallyOpen}/>
        }

      </ModalContainer>

      {props.children && props.children(scaffoldInfo)}
    </div>
  );
};
