import React from 'react'

let lastElementId = 0;

export function newElementId(prefix = "id-") {
  lastElementId += 1;
  return `${prefix}${lastElementId}`;
}

export const useElementId = (prefix?: string) => {
  const [elementId] = React.useState(newElementId(prefix))
  return elementId
}
