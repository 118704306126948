import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from '../icons';
import { useMedia } from 'react-media';
import { GRID_MEDIA_QUERIES } from '../media';
import { isSafariTouch } from '../utils/browser';
import styles from './FullPageContentPage.module.scss';


interface BackInfo {
  to: string,
  text?: string,
}

interface Props {
  back?: BackInfo
  title?: React.ReactNode
  actions?: React.ReactNode
  children?: React.ReactNode
  scrollContent?: boolean
  childrenFullHeight?: boolean
  sidebarVisible?: boolean
  flushContent?: boolean
  flushHeader?: boolean
  padding?: string
}

/*

Example Layout could be

_________________________________________________
| Song Name & Author       |     Action buttons |
| _________________________|____________________|
|                                               |
| Chord Chart                                   |
|                                               |
|_______________________________________________|

*/


export const FullPageContentPane = React.forwardRef<HTMLDivElement, Props>((props, ref) => {

  // Track whether the content is scrolled down or all to the top
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const newScrolled = e.currentTarget.scrollTop > 0;
    if (newScrolled !== scrolled) {
      setScrolled(newScrolled);
    }
  }

  const lgGte = useMedia({query: GRID_MEDIA_QUERIES.lgGTE});

  return (
    <div id="MainContentPane" ref={ref} style={{padding: props.padding}} className={cs(
      "flex-fill d-flex flex-column", 
      props.scrollContent && 'h-100 w-100',
      // isSafariTouch() && 'is-safari-touch',
      // document.webkitIsFullScreen && 'is-safari-fullscreen'
      
    )}>
        <div 
          id="main-content-header" 
          className={cs(
            styles.mainContentHeader  ,
            "d-flex align-items-center", 
            !props.flushHeader && 'py-lg-2',
            scrolled && 'bg-light border-bottom',
            (isSafariTouch() && document.webkitIsFullScreen) && styles.safariTouchFullScreenHeader,
          )}
          style={{transition: 'border 500ms, background 500ms', borderColor: 'white'}}
        >
          {props.back && !props.sidebarVisible ?
            <Link to={props.back.to} className={flushHeaderBtnClass} title="Back" style={{lineHeight: 'inherit'}}>
              {props.back.text || <FontAwesomeIcon icon={icons.back}/>}
            </Link>
            :
            <div className="ps-3"/>
          }
          {props.title}
          <div className={cs("ms-auto flex-shrink-0", !props.flushHeader && "me-sm-3")}>
            {props.actions}
          </div>
        </div>
      <div className={cs('flex-grow-1', !props.flushContent && 'px-0', props.scrollContent && 'overflow-auto')} onScroll={handleScroll}>
        {props.children}
      </div>
    </div>
  )
});


export const flushHeaderBtnClass = cs(styles.flushHeaderBtn, 'btn btn-link rounded-0 px-2 px-sm-3');
export const flushHeaderTxtClass = cs(styles.flushHeaderTxt, 'px-3');
