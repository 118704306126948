import { useEffect, useRef } from 'react';

export const useAddScrolledClassName = (scrollClassName: string) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        if (divRef.current.scrollTop > 0) {
          divRef.current.classList.add(scrollClassName);
        } else {
          divRef.current.classList.remove(scrollClassName);
        }
      }
    };

    const current = divRef.current;
    if (current) {
      current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return divRef;
};
