import React from "react"

interface LocalStateController<TValue>{
  get: () => TValue
  set: (value: TValue) => void
}

const createBooleanValue = (key: string): LocalStateController<boolean> => ({
  get: () => window.localStorage.getItem(key) === '1',
  set: (value: boolean) => window.localStorage.setItem(key, value ? '1' : '0')
})

// const createStringOptionsValue = <TValue>(key: string, default: TValue): LocalStateController<TValue> => ({
//   get: () => window.localStorage.getItem(key) ,
//   set: (value: boolean) => window.localStorage.setItem(key, value ? '1' : '0')
// })


export const localState = {
  // liveSyncEnabled: createBooleanValue('LIVE_SYNC_ENABLED'),
  // liveSyncSidebarDocked: createBooleanValue('LIVE_SYNC_SIDEBAR_DOCKED'),
  transposeType: {
    get: (): 'capo' | 'semitones' => window.localStorage.getItem('TRANSPOSE_TYPE') === 'capo' ? 'capo' : 'semitones',
    set: (value: 'capo' | 'semitones') => window.localStorage.setItem('TRANSPOSE_TYPE', value),
  }
}


export const useLocalState = <TValue extends unknown>(ctl: LocalStateController<TValue>): [TValue, (value: TValue) => void] => {
  const [state, setState] = React.useState(ctl.get())
  return [
    state, 
    (value: TValue) => {
      setState(value);
      ctl.set(value);
    }
  ]
};
