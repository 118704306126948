import 'bootstrap/dist/js/bootstrap';
import { useRouteMatch } from "react-router-dom";
import { AppError } from '../chrome/AppError';
import { Loading } from '../chrome/Loading';
import { LiveSyncAccess, useSetlistLinkQuery } from '../generated/graphql';
import { LiveSyncContext, useLiveSyncSetlistContextState } from '../livesync/LiveSyncContext';
import { SharedSetlist } from './SetlistLink';
import { setlistAppRoutes } from './routes';


export const SetlistLinkApp = () => {

  // Shared setlists don't require auth, so fetch here at top level
  const sharedSetlistMatch = useRouteMatch<{setlistkey: string}>(setlistAppRoutes.setlistLink.pattern)
  const setlistKey = sharedSetlistMatch?.params.setlistkey ?? null;
  const [query, reload] = useSetlistLinkQuery({
    variables: {key: setlistKey ?? ''},
    pause: !sharedSetlistMatch?.params.setlistkey,
  });

  // Live Sync
  const liveSyncData = useLiveSyncSetlistContextState({
    setlistKey,
    startEnabled: query.data?.setlistLink?.liveSyncDefaultStatus ?? false,
    allowed: !!query.data?.setlistLink && (query.data.setlistLink.liveSyncAccess !== LiveSyncAccess.Disabled),
  });

  // Render loading or error state
  if (!query.data?.setlistLink || sharedSetlistMatch === null) {

    if (query.fetching)
      return <Loading/>;

    if (query.error?.networkError) {
      return (
        <AppError errorMessage={query.error.networkError.message}>
          Are you connected to the internet?<br/>
          Or perhaps [although unlikely] songlib is down?
        </AppError>
      );
    }

    if (query.error?.graphQLErrors) {
      return (
        <AppError>
          This is probably our fault...<br/>
          so contact us if this keeps happening.
        </AppError>
      );
    }
    
    return (
      <AppError title='No Songlib Setlist found at this link.'>
        Your link has likely expired or it may be invalid.
      </AppError>
    );
  }

  return (
    <LiveSyncContext.Provider value={liveSyncData}>
      <SharedSetlist 
        setlistLink={query.data.setlistLink}
        setlistKey={sharedSetlistMatch.params.setlistkey} 
      />
    </LiveSyncContext.Provider>
  );
};
