import cs from 'classnames';
import React from 'react';

interface Props {
  onClick: () => void
  hiding: boolean
  initiallyShown?: boolean
}

export const OffCanvasBackdrop = (props: Props) => {
  console.log('OffCanvasBackdrop', props.hiding)
  const [initialRender, setInitialRender] = React.useState(true);
  React.useEffect(() => {
    setInitialRender(false);
  }, [])
  return (
    <div 
      className={cs(
        "offcanvas-backdrop fade", 
        (!initialRender || props.initiallyShown) && !props.hiding && 'show',
      )} 
      onClick={props.onClick}
    />
  );
};
