import { faDownload, faHeartbeat, faWavePulse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleSwitch } from '../components/ToggleSwitch';
import dates, { useDates } from '../dates';
import { SetlistLinkFragment } from '../generated/graphql';
import { LiveSyncContextData } from '../livesync/LiveSyncContext';
import { settings } from '../settings';
import cs from 'classnames';
import { IntervalRenderer } from '../components/IntervalRerender';


interface Props {
  setlistLink: SetlistLinkFragment
  liveSync: LiveSyncContextData
}


export const OffCanvasLiveSync = (props: Props) => {
  const {liveSync, setlistLink} = props;
  const c = liveSync.currentSong;
  return (
    <>
      <div className={cs('border-top', liveSync.enabled && 'border-success')}/>
      <div className={cs('px-3 py-2 bg-gradient', liveSync.enabled ? 'bg-success bg-opacity-25' : 'bg-light')} style={{transitionProperty: 'background-color', transitionDuration: '0.3s'}}>
        <div className='d-flex align-items-center justify-content-between mb-2'>
          <ToggleSwitch value={liveSync.enabled} onChange={liveSync.setEnabled} bold>Live Sync</ToggleSwitch>
          {c && (
            <div className='small text-end' style={{lineHeight: '1.1'}}>
              <IntervalRenderer msInterval={5000}>
                <div><FontAwesomeIcon icon={faWavePulse}/> Last update: {dates.timeSince(c.timestamp)}</div>
              </IntervalRenderer>
              {/* <div>by {c.user?.shortName ?? 'Anonymous'}</div> */}
            </div>
          )}
        </div>
        {c && 
          <>
          {/* <IntervalRenderer msInterval={5000}>
            <div><FontAwesomeIcon icon={faWavePulse}/> Last push {dates.timeSince(c.timestamp)}</div>
          </IntervalRenderer> */}
            <div>{c.song.title}</div>
            <div className='small d-flex align-items-center justify-content-between'>
              
              <span>Key: <b>{c.transpose.toKey || 'N/A'}</b></span>
              <span>Slide: <b>{c.slide + 1}</b></span>
              <span>Pushed By: <b>{c.user?.shortName ?? 'Anonymous'}</b></span>
              {/* <span>When: <b>{dates.timeSince(c.timestamp)}</b></span> */}
            </div>
            <div className='small text-end' style={{lineHeight: '1.1'}}>
              {/* <IntervalRenderer msInterval={5000}>
                <div><FontAwesomeIcon icon={faWavePulse}/> Last push {dates.timeSince(c.timestamp)}</div>
              </IntervalRenderer> */}
              {/* <div>by {c.user?.shortName ?? 'Anonymous'}</div> */}
            </div>
          </>
        }
      </div>
    </>
  );
};