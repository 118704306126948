import cs from 'classnames';
import { NamedTypeNode } from 'graphql';
import React from "react";
import { Line, transposePlainTextChordChart } from "../transpose/chordcharts";
import styles from "./ChordChart.module.scss";
import { calculateLayout, FONT_ASPECT_RATIO, Layout } from "./ChordChartLayout";


const HeadingLine = (props: { line: Line }) => (
  <div className={styles.heading}>{props.line.content}</div>
);


const GeneralLine = (props: { line: Line }) => (
  <div className={styles[props.line.type]}>
    {props.line.content || <span>&nbsp;</span>}
  </div>
);


interface Props extends Layout {
  liveSlide?: number;
  pixelsNarrowerThanVw: number;
  pixelsShorterThanVh: number;
}


const ChordChartInner = React.memo((props: Props) => (
  <div
    className={cs(
      `chordchart d-flex justify-content-between`,
      styles.ChordChart,
      props.columns.length === 2 ? styles.twoCol : styles.oneCol
    )}
    style={{
      fontSize: `max(14px, min( calc((100vw - ${props.pixelsNarrowerThanVw}px) / 0.501 / ${props.charsWide}), calc((100vh - ${props.pixelsShorterThanVh}px) / (0.501 * 2) / ${props.linesHigh}) ) )`,
    }}
    data-component="ChordChartInner"
  >
    {props.columns.map((col, colIndex) => (
      <div 
          key={colIndex} 
          className={cs(styles.col, styles[`col${colIndex + 1}`])}
          style={{marginRight: `calc(5em / ${FONT_ASPECT_RATIO})`}}
      >
        {col.verses.map((v, index) => (
          <React.Fragment key={index}>
            <div
              
              className={cs(
                'verse', 
                styles.verse, 
                v.slideNumber !== null && "hasslide",
                props.liveSlide === v.slideNumber && "live-slide"
              )}
              style={{flex: '1 1 0'}}
            >
              {v.items.map((line, index) =>
                line.type === "heading" ? (
                  <HeadingLine line={line} key={index} />
                ) : (
                  <GeneralLine line={line} key={index} />
                )
              )}

            </div>
            {index + 1 < col.verses.length &&
              <div className={cs(styles.lyrics, 'spacer')}>&nbsp;</div>
            }
          </React.Fragment>
        ))}
      </div>
    ))}
    <div style={{ clear: "both" }} />
  </div>
));


export const ChordChartDisplay = React.memo((props: {
  plainText: string;
  maxWidth: number;
  maxHeight: number;
  liveSlide?: number;
  transpose: number;
  style: "#" | "b";
}) => {
  
  const transposedVerses = React.useMemo(
    () => transposePlainTextChordChart(props.plainText, props.transpose, props.style), 
    [props.plainText, props.transpose, props.style]
  );
  
  const layout = calculateLayout(
    transposedVerses,
    props.maxWidth,
    props.maxHeight,
    'VariableWidth',
    // 'EqualWidth',
  );

  return (
    <ChordChartInner 
      {...layout} 
      liveSlide={props.liveSlide}
      pixelsNarrowerThanVw={window.innerWidth - props.maxWidth}
      pixelsShorterThanVh={window.innerHeight - props.maxHeight}
    />
  );
});
