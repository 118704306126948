import React from 'react';
import { LibraryFragment } from '../generated/graphql';
import { buildLinkForLibrary } from '../routes';


export interface LibraryContextData {
  id: number
  buildLink: ReturnType<typeof buildLinkForLibrary>
  readOnly: boolean
  library: LibraryFragment | null
}

export const LibraryContext = React.createContext<LibraryContextData>({
  id: 0,
  buildLink: buildLinkForLibrary(0),
  readOnly: true,
  library: null,
});
