import { faExpand } from '@fortawesome/pro-regular-svg-icons';
import { faCompress } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';


declare global {
  interface Document {
    webkitExitFullscreen: undefined | (() => Promise<void>);
    webkitIsFullScreen: boolean | undefined
  }

  interface HTMLElement {
    webkitRequestFullscreen?: undefined | (() => Promise<void>);
  }
}


interface Options {
  element: HTMLElement | null
}

export interface FullScreenInfo {
  isFullScreen: boolean;
  setFullScreen: (value?: boolean) => Promise<void>;
  available: boolean;
}

export const useFullScreenStd = ({element}: Options): FullScreenInfo => {
  const [state, setState] = React.useState<boolean>(document.fullscreenElement !== null);

  React.useEffect(() => {

    // Start listening
    const handler = () => {
      setState(document.fullscreenElement !== null);
    };
    document.addEventListener('fullscreenchange', handler);

    // Stop listening of destroy
    return () => {
      document.removeEventListener('fullscreenchange', handler);
    }
  }, []);

  return {
    isFullScreen: document.fullscreenElement !== null,
    setFullScreen: async (value?: boolean) => {
      if (value === undefined) {
        value = document.fullscreenElement === null;
      }

      if (value && element?.requestFullscreen) {
        await element.requestFullscreen();
      }
      if (!value && document.exitFullscreen) {
        await document.exitFullscreen();
      }
      setState(value);
    },
    available: !!element?.requestFullscreen,
  }
}


export const useFullScreenSafari = ({element}: Options) => {

  const isFullScreen = () => !!(document.webkitIsFullScreen);
  
  // State is here mostly as a hack so that rerendering happens
  const [state, setState] = React.useState<boolean>(isFullScreen());
  React.useEffect(() => {

    // Start listening
    const handler = () => {
      setState(document.webkitIsFullScreen ?? false);
    };
    document.addEventListener('webkitfullscreenchange', handler);

    // Stop listening of destroy
    return () => {
      document.removeEventListener('webkitfullscreenchange', handler);
    }
  }, []);

  return {
    isFullScreen: isFullScreen(),
    setFullScreen: async (value?: boolean) => {

      if (value === undefined) {
        value = !isFullScreen();
      }

      if (value && element?.webkitRequestFullscreen) {
        await element.webkitRequestFullscreen();
      }

      if (!value && document.webkitExitFullscreen) {
        await document.webkitExitFullscreen();
      }
    },
    available: !!element?.webkitRequestFullscreen,
  }
}


export const useFullScreen = (options: Options) => {
  console.log('useFullScreen', options)
  const std = useFullScreenStd(options);
  const safari = useFullScreenSafari(options);

  const fs = (!std.available && safari.available) ? safari : std;


  return {
    ...fs,
    ToggleButton: (props: {className?: string}) => {
      if (fs.available) return (
        <button 
          className={cs(props.className ?? 'btn btn-light px-2 py-1 ms-3 me-3', fs.isFullScreen && 'active', )} 
          onClick={() => fs.setFullScreen()}
        >
          <FontAwesomeIcon icon={fs.isFullScreen ? faCompress : faExpand}/>
        </button>
      );
      return <></>
    }
  };
}


export const FullScreenToggleButton = (props: FullScreenInfo & {className?: string}) => (
  props.available ? 
      <button 
        className={cs(props.className ?? 'btn btn-light px-2 py-1 ms-3 me-3', props.isFullScreen && 'active')} 
        onClick={() => props.setFullScreen()}
      >
        <FontAwesomeIcon icon={props.isFullScreen ? faCompress : faExpand} className="d-lg-none"/>
        <span className='d-none d-lg-inline'>Full Screen</span>
      </button>
    : 
      <></>
);


interface Props {
  className?: string
  children?: (fs: FullScreenInfo) => React.ReactNode
}


export const FullScreenContainer = (props: Props) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const std = useFullScreenStd({element: ref.current});
  const safari = useFullScreenSafari({element: ref.current});
  const fs = (!std.available && safari.available) ? safari : std;
  return (
    <div ref={ref} className={props.className}>
      {props.children && props.children(fs)}
    </div>
  )
};
