import { faBars, faListMusic, faWavePulse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import dates from '../dates';
import { useMedia } from 'react-media';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { flushHeaderBtnClass, FullPageContentPane } from '../chrome/FullPageContentPane';
import { H1 } from '../chrome/H1';
import { Loading } from '../chrome/Loading';
import { LiveSyncAccess, SetlistLinkFragment } from '../generated/graphql';
import { useLiveSyncContext } from '../livesync/LiveSyncContext';
import { LiveSyncSongList } from '../livesync/LiveSyncSongList';
import { GRID_MEDIA_QUERIES } from '../media';
import { sessionState, useSessionState } from '../storage/sessionState';
import { FullScreenToggleButton, useFullScreen } from '../utils/fullScreen';
import { GettingStarted } from './GettingStarted';
import { PinnableOffCanvas } from './PinnableOffCanvas';
import { setlistAppRoutes } from './routes';
import styles from './SetlistLink.module.scss';
import { SetlistListSongActions } from './SetlistLinkSongActions';
import { SetlistLinkSongContent } from './SetlistLinkSongContent';
import { OffCanvasSetlistMeta } from './OffCanvasSetlistMeta';
import { OffCanvasLiveSync } from './OffCanvasLiveSync';
import { IntervalRenderer } from '../components/IntervalRerender';


interface Props {
  setlistKey: string
  setlistLink: SetlistLinkFragment
}

const bodyElement = document.getElementById('body');
if (bodyElement === null) {
  throw Error('No element with #body (should be the body element)')
}

export const SharedSetlist = (props: Props) => {
  const { setlist } = props.setlistLink
  const [myTranposes, setMyTransposes] = useSessionState(sessionState.mySetlistTransposes);

  const songMatch = useRouteMatch<{setlist: string, key: string, setlistsong: string}>(setlistAppRoutes.setlistSong.pattern);
  const setlistSongId = (songMatch && parseInt(songMatch.params.setlistsong)) || null;
  const setlistSong = setlist.songs.filter(s => s.id === setlistSongId)[0] || null;
  const setlistSongInfo = setlistSong && (() => {
    const index = setlist.songs.findIndex(s => s.id === setlistSongId) ;
    const prevSong = setlist.songs[index  - 1];
    const nextSong = setlist.songs[index  + 1];
    return {
      index,
      number: index + 1,
      prevSong,
      nextSong,
      prevLink: prevSong ? setlistAppRoutes.setlistSong.build(props.setlistKey, prevSong.id) : null,
      nextLink: nextSong ? setlistAppRoutes.setlistSong.build(props.setlistKey, nextSong.id) : null,
      semitones: myTranposes[setlistSong.id] ?? 0
    }
  })() || null;

  const contentRef = React.useRef<HTMLDivElement>(null);
  const fullScreen = useFullScreen({element: bodyElement});
  const liveSync = useLiveSyncContext();

  const buildSongLink = (setlistSongId: number) => `/${props.setlistKey}/${setlistSongId}/`;

  // Navigate to live song when changed
  const history = useHistory();
  React.useEffect(() => {
    if (liveSync.active && liveSync.enabled && liveSync.currentSong?.setlistSong?.id) {
      history.push(buildSongLink(liveSync.currentSong.setlistSong.id))
    }
  }, [liveSync.active, liveSync.enabled, liveSync.currentSong?.id]);

  const songs = liveSync.currentSong?.setlist.songs ?? setlist.songs;

  const gteMd = useMedia({query: GRID_MEDIA_QUERIES.mdGTE});

  return (
    <PinnableOffCanvas 
      className={cs('h-100 w-100 bg-white', styles.wrap)} 
      ref={contentRef}
      offCanvasTitle={<Link to={`/${props.setlistKey}/`} className="text-decoration-none text-dark">{setlist.title}</Link>}
      placement='end'
      initiallyOpen={setlistSongId === null}
      closeDisabled={setlistSongId === null}
      pinningDisabled={setlistSongId === null}
      offCanvasContent={poc => <>
        <OffCanvasSetlistMeta
          liveSync={liveSync}
          setlistLink={props.setlistLink}
        />
        <LiveSyncSongList 
          setlist={setlist}
          songs={songs}
          buildSongLink={setlistSongId => buildSongLink(setlistSongId)}
          setlistKey={props.setlistKey}
          showPush={props.setlistLink.liveSyncAccess === LiveSyncAccess.AllowPush}
          flush
          notes
          liveId={liveSync.currentSong?.setlistSong?.id}
          selectedId={setlistSongId}
          onPushSong={() => { if (!poc.pinned) poc.hide(); }}
          onSelectSong={() => { if (!poc.pinned) poc.hide(); }}
        />
        {props.setlistLink.liveSyncAccess !== LiveSyncAccess.Disabled &&
          <OffCanvasLiveSync
            liveSync={liveSync}
            setlistLink={props.setlistLink}
          />
        }
      </>}
    >
      {poc => 
        <FullPageContentPane
          title={
            setlistSongInfo && setlistSong 
            ? <H1>{setlistSongInfo.number}. {setlistSong.title}</H1> 
            : undefined
          }
          actions={
            <div className='btn-toolbar'>
              {setlistSongInfo && setlistSong && 
                <SetlistListSongActions 
                  setlistSong={setlistSong}
                  semitones={setlistSongInfo.semitones}
                  onTranspose={semitones => setMyTransposes({...myTranposes, [setlistSong.id]: semitones})}
                />
              }
              <FullScreenToggleButton className={flushHeaderBtnClass} {...fullScreen}/>
              {!((poc.pinned === 'pinned' || poc.pinned === 'pinning') && (poc.open === 'show' || poc.open === 'showing')) &&
                <button 
                  className={cs(
                    flushHeaderBtnClass, 
                    'text-decoration-none', 
                    // liveSync.enabled && 'text-success',
                    )} 
                  type="button" 
                  onClick={poc.toggleOpen} 
                  aria-controls={poc.offCanvasId}
                >
                  <FontAwesomeIcon icon={faBars} className='me-2'/>
                  {/* <FontAwesomeIcon icon={faListMusic} className='me-2'/> */}
                  <span className='d-none d-md-inline'>Songs</span>
                </button>
              }
            </div>
          }
          scrollContent
          flushHeader
        >
          {setlistSongInfo && setlistSong
            ? 
              <SetlistLinkSongContent
                setlistSong={setlistSong}
                semitones={setlistSongInfo.semitones}
                onPrev={() => setlistSongInfo.prevLink && history.push(setlistSongInfo.prevLink)}
                onNext={() => setlistSongInfo.nextLink && history.push(setlistSongInfo.nextLink)}
                sizeSource={poc.pinned}
                browseArrows={!((poc.pinned === 'pinned' || poc.pinned === 'unpinning') && (poc.open === 'show' || poc.open === 'hiding'))}
              />
            : 
              <div style={{paddingRight: '400px'}}>
                <Loading>
                  <GettingStarted 
                    liveSyncEnabled={liveSync.enabled} 
                    showHowToPush={props.setlistLink.liveSyncAccess === LiveSyncAccess.AllowPush}
                    muted={poc.pinned === 'pinned'}
                  />
                </Loading>
              </div>
          }
          {liveSync.enabled && 
            <div 
              onClick={poc.toggleOpen} 
              style={{cursor: 'pointer'}}
              className='d-none d-sm-block position-fixed end-0 bottom-0 bg-success text-white small px-2 py-0'
            >
              <IntervalRenderer msInterval={5000}>
                <FontAwesomeIcon icon={faWavePulse} className='me-1'/>Live Syncing 
                {/* <span className='ps-3'/> 
                (last update {dates.timeSince(liveSync.currentSong?.timestamp)}) */}
              
                
              </IntervalRenderer>
            </div>}
        </FullPageContentPane>
      }
    </PinnableOffCanvas>
  );
};
