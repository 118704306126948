import { Verse } from "../transpose/chordcharts";

export const FONT_ASPECT_RATIO = 1.99672012;

interface Column {
  linesHigh: number;
  charsWide: number;
  verses: Verse[];
}


export interface Layout {
  columns: Column[];
  linesHigh: number;
  charsWide: number;
}


//  Auto Scale Text
export const calculateLayout = (
  verses: Verse[],
  maxWidth: number,
  maxHeight: number,
  columnType: 'EqualWidth' | 'VariableWidth',
): Layout => {

  const combos = getAllColumnOptionsWithSizing(verses)
    .map(combo => ({
      ...combo,
      width: columnType === 'EqualWidth' ? combo.widthIfFixed : combo.widthIfVaried,
    }))
    .map(combo => ({
      ...combo,
      scaleH: maxHeight / (combo.height * FONT_ASPECT_RATIO),
      scaleW: maxWidth / combo.width,
    }))
    .map(combo => ({
      ...combo,
      scale: Math.min(combo.scaleW, combo.scaleH)
    }))
    .sort((a,b) => {
      // If they are the same, let's work out the nicest looking option
      if (a.scale === b.scale) {
        if (a.scaleH === b.scaleH) return b.scaleW - a.scaleW;
        if (a.scaleW === b.scaleW) return b.scaleH - a.scaleH;
      }
      // fall back
      return b.scale - a.scale; 
    });
  
  const optimal = combos[0];

  // Not sure this could ever happen
  if (optimal === undefined) return {columns: [], linesHigh: 0, charsWide: 0};

  return {
    columns: optimal.columns.map(c => ({
      linesHigh: c.height,
      charsWide: c.width,
      verses: c.verses,
    })), 
    linesHigh: optimal.height, 
    charsWide: optimal.width,
  };

};


const getPossibleColumnCombinations = (nVerses: number) : number[][] => {

  var fn = function(active: number[], rest: number[], a: number[][]) {
    if (active.length === 0 && rest.length === 0){
        return;
    }
    const rest0 = rest[0];
    if (rest0 === undefined) {
        a.push(active);
    } 
    else {
        fn([...active, rest0], rest.slice(1), a);
        fn(active, rest.slice(1), a);
    }
    return a;
  }

  const all_combinations = fn([], [...new Array(nVerses)].map((_, i) => i), []) ?? [];

  return all_combinations.filter(c => c[0] === 0);
}


const getAllVerseColumnOptions = (verses: Verse[]) : Verse[][][] => {

  const colCombos = getPossibleColumnCombinations(verses.length);

  const verseCombos: Verse[][][] = []

  for (const combo of colCombos) {
    const vc: Verse[][] = []
    for (let i = 0 ; i < combo.length ; i++) {
      const vs = verses.slice(combo[i], i + 1 < combo.length ? combo[i+1] : undefined);
      vc.push(vs)
    }
    verseCombos.push(vc)
  }

  return verseCombos;
}

const getAllColumnOptionsWithSizing = (verses: Verse[]) => {
  const combos = getAllVerseColumnOptions(verses);
  return combos
    .map(combo => combo.map(col => ({
      width: Math.max(...col.map(v => v.width())),
      height: col.reduce((acc, v) => acc + v.height(), 0) + col.length,
      verses: col,
    })))
    .map(columns => ({
      columns,
      height: Math.max(...columns.map(c => c.height)),
      widthIfVaried: 
        // Sum of all column widths
        columns.reduce((acc, col) => acc + col.width, 0) 
        // with a 5 char gap in-between columns
        + (columns.length - 1) * 5,
      widthIfFixed: 
        // Widest column x nColumns
        Math.max(...columns.map(col => col.width)) * columns.length
        // with a 5 char gap in-between columns
        + (columns.length - 1) * 5,
    }))
}
