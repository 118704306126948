import React from "react";
import { TransposeStyle } from "../generated/graphql";
import { transposeNote } from "./transpose";


export interface DisplayKey {
  semitones: number;
  style: TransposeStyle;
}
interface Options extends DisplayKey {
  originalKey: string;
  onChange?: (value: DisplayKey) => void;
  onSave?: () => void;
  // align?: "left" | "right"
  // compact?: boolean;
  // btnClass?: string;
}

// export type KeySelectProps = Props;

export interface KeyChoice {
  value: number;
  display: string;
};

type BtnMouseEvent = React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>

export type TransposeEvent = (event: BtnMouseEvent, value?: number, style?: TransposeStyle) => void;

function choices(): KeyChoice[] {
  const items: { value: number; display: string }[] = [];
  for (let i = 6; i >= -5; i--) {
    items.push({
      value: i,
      display: i > 0 ? `+${i}` : i === 0 ? "Default" : i.toString()
    });
  }
  return items;
}

export const useKeySelect = (options: Options) => { 
  const currentKey = transposeNote(
    options.originalKey,
    options.semitones,
    options.style === TransposeStyle.Flats ? 'b' : '#',
  );
  

  const handleTranspose: TransposeEvent = (event: BtnMouseEvent, value?: number, style?: TransposeStyle) => {
    let v = value === undefined ? options.semitones : value;
    while (v < -5) v = v + 12;
    while (v > 6) v = v - 12;
    if (options.onChange){
      event.preventDefault()
      options.onChange({ semitones: v, style: style || options.style });
    }
  };

  const handleStyle = (event: BtnMouseEvent, value: TransposeStyle) => {
    if (options.onChange){
      event.preventDefault()
      options.onChange({ style: value, semitones: options.semitones });
    }
  };

  return {
    ...options,

    choices,

    handleTranspose,

    handleTransposeDown: (e: BtnMouseEvent) => handleTranspose(e, options.semitones - 1),

    handleTransposeUp: (e: BtnMouseEvent) => handleTranspose(e, options.semitones + 1),

    handleStyle,

    handleStyleSharps: (e: BtnMouseEvent) => handleStyle(e, TransposeStyle.Sharps),

    handleStyleFlats: (e: BtnMouseEvent) => handleStyle(e, TransposeStyle.Flats),

    currentKey,

    keyDisplay: currentKey
      ? currentKey
      : `${options.semitones > 0 ? "+" : ""}${options.semitones}`,

  }
}

export type UseKeySelect = ReturnType<typeof useKeySelect>

// export class KeySelect extends React.Component<Props> {


//   render() {
//     return (
//       <div className="btn-group">
//           <button
//             title="Tranpose down one semitone"
//             onClick={e => this.handleTranspose(e, this.props.semitones - 1)}
//             className={cs(this.props.btnClass || "btn btn-outline-secondary")}
//           >
//             <FontAwesomeIcon icon={icons.keyDown}/>
//           </button>
//           <button
//             data-bs-toggle="dropdown"
//             title="Select Key"
//             style={{ minWidth: "50px"  }}
//             className={cs(this.props.btnClass || "btn btn-outline-secondary")}
//           >
//             {keyDisplay}
//           </button>
//           <button
//             title="Tranpose up one semitone"
//             onClick={e => this.handleTranspose(e, this.props.semitones + 1)}
//             className={cs(this.props.btnClass || "btn btn-outline-secondary")}
//           >
//             <FontAwesomeIcon icon={icons.keyUp}/>
//           </button>

//         <div className={cs("dropdown-menu", this.props.align && 'dropdown-menu-right', 'dropdown-menu-iconed')}>
//           {this.choices().map(k => (
//             <Link
//               key={k.value}
//               className={cs('d-flex', 'dropdown-item', this.props.semitones === k.value ? "active" : "", k.value === 0 && 'font-weight-bold')}
//               to={`?transpose=${k.value}&style=${this.props.style}`}
//               onClick={e => this.handleTranspose(e, k.value)}
//             >
//               {currentKey ? (
//                 <>
//                   <span className="me-auto">
//                     {transposeNote(
//                       this.props.originalKey,
//                       k.value,
//                       this.props.style === TransposeStyle.Flats ? 'b' : '#'
//                     )}{" "}
//                   </span>
//                   <small className={cs(this.props.semitones === k.value ? "" : "text-muted",  k.value === 0 && 'font-weight-bold')}>{k.display}</small>
//                 </>
//               ) : (
//                 k.display
//               )}
//             </Link>
//           ))}

//           {/* {this.props.transpose !== 0 && ( */}
//             <>
//               <div className="dropdown-divider" />
//               <Link
//                 to={`?transpose=${this.props.semitones}#`}
//                 onClick={e => this.handleTranspose(e, undefined, TransposeStyle.Sharps)}
//                 className={cs('dropdown-item', this.props.style === TransposeStyle.Sharps ? "active" : "")}
//               >
//                 <span className="dropdown-item-texticon"><b>♯</b></span>
//                 Show with sharps
//               </Link>
//               <Link
//                 to={`?transpose=${this.props.semitones}b`}
//                 onClick={e => this.handleTranspose(e, undefined, TransposeStyle.Flats)}
//                 className={cs('dropdown-item', this.props.style === TransposeStyle.Flats ? "active" : "")}
//               >
//                 <span className="dropdown-item-texticon"><b>&#9837;</b></span>
//                 Show with flats 
//               </Link>
//               {currentKey && this.props.semitones !== 0 && this.props.onSave && (
//                 <>
//                   <div className="dropdown-divider" />
//                   <a className="dropdown-item" href="." onClick={e => {e.preventDefault(); if (this.props.onSave) this.props.onSave()}}>
//                     <FontAwesomeIcon icon={icons.saveKey}/>Save song in this key
//                   </a>
//                 </>
//               )}
//             </>
//           {/* )} */}
//         </div>
//       </div>
//     );
//   }
// }
