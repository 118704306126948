import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SetlistSongFragment, TransposeStyle } from '../generated/graphql';
import { ChordChartAutoSizing } from '../song/ChordChartAutoSizing';
import { useAddScrolledClassName } from '../utils/scroll';
import styles from './SetlistLinkSongContent.module.scss';
import cs from 'classnames';


interface Props {
  setlistSong: SetlistSongFragment
  onPrev: () => void
  onNext: () => void
  semitones: number
  sizeSource?: any
  browseArrows: boolean
}


export const SetlistLinkSongContent = (props: Props) => {
  const {semitones, setlistSong: s} = props;

  const totalSemitones = s.transpose.semitones + semitones;
  const scrollRef = useAddScrolledClassName(styles.Scrolled ?? 'scrolled');
  return (
    <>
      <div className={cs('d-flex h-100 m-lg-0', styles.Container)} data-component="SetlistLinkSongContent">

        {props.browseArrows &&
          <div className='ps-2 pe-0 align-items-center d-none d-lg-flex'>
            <button className='btn btn-link p-2 h-50 mt-n5' disabled={!props.onPrev} onClick={() => props.onPrev && props.onPrev()}>
              <FontAwesomeIcon icon={faChevronLeft} size="2x"/>
            </button>
          </div>
        }
        <div className={cs('flex-grow-1 h-100 overflow-auto px-3', styles.Content)} ref={scrollRef}>

          <ChordChartAutoSizing
            sizeSource={props.sizeSource}
            plainText={s.song.chordChart || 'asdf'}
            transpose={totalSemitones === 0 ? undefined : {semitones: totalSemitones, style: s.transpose.style ?? TransposeStyle.Sharps}}
          />
        </div>
        {props.browseArrows &&
          <div className='ps-2 pe-1 align-items-center d-none d-lg-flex'>
            <button className='btn btn-link p-2 h-50 mt-n5' disabled={!props.onNext} onClick={() => props.onNext && props.onNext()}>
              <FontAwesomeIcon icon={faChevronRight} size="2x"/>
            </button>
          </div>
        }
      </div>
    </>
  );
};

