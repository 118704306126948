import React from 'react'


interface Props {
  children?: React.ReactNode
}


export const Loading = (props: Props) => {
  return (
    <div className="d-flex h-100 align-items-center flex-fill">
      <div className="flex-fill text-center p-3">
        {props.children || 'Loading...'}
      </div>
    </div>
  );
};


