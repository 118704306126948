import cs from 'classnames';
import { EditorChangeCancellable } from 'codemirror';
import 'codemirror/lib/codemirror.css';
import React from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import { FieldCommitEvent, FieldConfig, ValidationStatus } from '../fields/hooks';
import { useElementId } from '../hooks/elementIds';
import styles from './SongChordChartField.module.scss';
import { SongChordChartHelpModal } from './SongChordChartHelpModal';
require('./SongChordChartField.CodeMirror')  // This extends CodeMirror so that we can 

declare const $: any;

export interface Props {

  initialValue: string
  validationStatus? : ValidationStatus
  wrapperClassName?: string
  onBlur: (value: string) => void
}

type LineType = 'heading' | 'chords' | 'lyrics' | 'other'

const lineTypes: {name: LineType, re: RegExp, display: string, icon: null}[] = [
  {name: 'heading', display: 'Heading', re: new RegExp(' *\\[(.*)\\] *', 'g'), icon: null},
  {name: 'chords', display: 'Chords', re: new RegExp('^[>\.](.*)$', 'g'), icon: null},
  {name: 'lyrics', display: 'Lyrics', re: new RegExp('^ .*$', 'g'), icon: null},
  {name: 'other', display: 'Other', re: new RegExp('.*', 'g'), icon: null},
]

const determineLineType = (line: string): LineType => {
  for (let t of lineTypes) {
    // console.log(t.re.exec(line))
    if (line.match(t.re)) {
      return t.name
    }
  }
  return 'other'
}

interface State {
  // validationStatus: ValidationStatus
  // error: string | null
  focused: boolean
  lineType: LineType
}

export const SongChordChartField = <T extends string>(props: Props) => {
  const modalId = useElementId('chordchart');
  const [state, setState] = React.useState<State>({
    // validationStatus: props.startValidated ? 'is-valid' : '',
    // error: null,
    focused: false,
    lineType: 'other',
  })
  
  return (
    <div className={cs("form-control d-flex flex-column ", styles.FormField, props.wrapperClassName, state.focused && styles.Focused, props.validationStatus)}>
      <div className={cs(styles.Toolbar, 'bg-light d-flex')}>
        <div className="btn-group">
          {lineTypes.filter(l => l.name !== 'other').map(l => (
            <button key={l.name} className={cs('btn btn-light btn-sm rounded-0 text-capitalize', l.name === state.lineType && 'active')}>{l.name}</button>
          ))}
        </div>
        <button className={cs('btn btn-light btn-sm border-left rounded-0 disabled')} type='button'>Paste from web&hellip; </button>
        <button className={cs('btn btn-light btn-sm border-left border-right rounded-0 me-auto')} data-bs-toggle="modal" data-bs-target={'#' + modalId} type='button'>Formatting guide&hellip; </button>
        <button className={cs('btn btn-light btn-sm rounded-0 ps-0', styles.Validation, props.validationStatus)} data-bs-toggle="modal" data-bs-target={'#' + modalId} type='button'>
          {props.validationStatus === 'is-invalid' && 'Invalid'}
          {props.validationStatus === 'is-valid' && 'Saved'}
          {props.validationStatus === 'is-validating' && <>Saving</>}
          {/* {f.validationStatus === '' && <>Autosave enabled</>} */}
        </button>
      </div>
      {/* {f.error !== null && <div className="bg-danger text-white p-3">{f.error}</div>} */}
      <CodeMirror
        value={props.initialValue}
        options={{
          indentUnit: 1,
          mode: "chordchart",
        }}
        onBeforeChange={(editor, data, value) => {
          // Disables the MacOS and iOS "Add period with double-space" feature.
          const data2 = data as EditorChangeCancellable;
          if (data2.update && data.origin === '+input' && data.text.length === 1 && data.text[0] === ". ") {
            data2.update(data.from, data.to, ["  "])
          } 
        }}
        onFocus={() => setState({...state, focused: true})}
        onBlur={async (editor) => {
          setState({...state, focused: false})
          props.onBlur(editor.getValue())
          // if (props.onCommit){
          //   setState({...state, validationStatus: 'is-validating'})
          //   const r = await props.onCommit({name: props.name, value: editor.getValue()});
          //   if (r) {
          //     setState({...state, error: r, validationStatus: 'is-invalid'})
          //   }
          //   else {
          //     setState({...state, validationStatus: 'is-valid'})
          //   }
          // }
        }}
        onCursor={(editor, data) => {
          const line = editor.getDoc().getLine(data.line)
          const lineType = determineLineType(line)
          setState({...state, lineType: lineType})
        }}
        className={cs(styles.CodeMirror, 'flex-fill overflow-auto', state.focused && 'has-focus', props.validationStatus)}
      />
      <SongChordChartHelpModal id={modalId}/>
    </div>
  )
}