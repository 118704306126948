import dayjs from 'dayjs';

// Enable customParseFormat dayJs plugin
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MeFragment } from './generated/graphql';
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)


export type DateObj = dayjs.Dayjs;

export type UserFormat = string | MeFragment;

const parseGraphDate = (isoDateStrOrDateObj: string | DateObj): DateObj => 
  typeof isoDateStrOrDateObj === 'string' ? dayjs(isoDateStrOrDateObj) : isoDateStrOrDateObj;

const parseUserDate = (userDateStr: string, userFormat: string): DateObj => 
  dayjs(userDateStr, convertUserFormatStrToDayJS(userFormat), true);

const convertUserFormatStrToDayJS = (userFormat: string) => userFormat.toUpperCase();

const cleanUserFormat = (userFormat: UserFormat | null | undefined) => {
  if (typeof userFormat === 'string') {
    return convertUserFormatStrToDayJS(userFormat)
  }
  else if (userFormat) {
    return convertUserFormatStrToDayJS(userFormat.preferences.dateFormat)
  }
  else {
    return 'YYYY-MM-DD'
  }
}

/* Takes a string or DateObj and formats it with the given user format */
const formatAsUser = (date: DateObj | string, userFormat: UserFormat | null): string => {
  if (typeof date === 'string') {
    return formatAsUser(parseGraphDate(date), userFormat);
  }
  else {
    return date.format(cleanUserFormat(userFormat))
  }
};

const convertToGraphString = (date: string, userFormat: string) => dayjs(date, userFormat).format('YYYY-MM-DD')

const isValidUserDate = (date: string, userFormat: string) => parseUserDate(date, userFormat).isValid()

const dates = {
  now: () => dayjs(),
  parseGraphDate,
  parseUserDate,
  formatAsUser,
  convertToGraphString,
  isValidUserDate,
  timeUntil: (date: string | DateObj | null | undefined) => date ? parseGraphDate(date).toNow() : '',
  timeSince: (date: string | DateObj | null | undefined) => date ? parseGraphDate(date).fromNow() : '',
  isToday: (date: DateObj) => dayjs().isSame(date, 'day'),
}

export default dates;


export const useDates = (config?: {userFormat: UserFormat | null | undefined}) => {
  const f = cleanUserFormat(config?.userFormat)
  return {
    parseGraphDate,
    parseUserDate: (userDateStr: string) => parseUserDate(userDateStr, f),
    formatAsUser: (date: DateObj | string) => formatAsUser(date, f),
    formatAsDayOfWeek: (date: DateObj | string) => parseGraphDate(date).format('dddd'),
    convertToGraphString: (userDateStr: string) => convertToGraphString(userDateStr, f),
    isValidUserDate: (userDateStr: string) => isValidUserDate(userDateStr, f),
  }
}