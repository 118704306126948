
const serverOrigin =  (process.env.REACT_APP_SERVER_ORIGIN ?? '').replace('{HOST}', window.location.hostname);

export const settings = {
  serverURL: serverOrigin,
  graphqlURL: serverOrigin + process.env.REACT_APP_GRAPHQL_PATH as string,
  graphqlWsURL: 
    serverOrigin
      .replace('http://', 'ws://') 
      .replace('https://', 'wss://') 
      + process.env.REACT_APP_GRAPHQL_WS_PATH as string,
  graphqlNoCredentials: (process.env.REACT_APP_NO_CREDENTIALS || '' as string).toLowerCase() === 'true',
}
