import { LibraryAccess } from "../generated/graphql";


export const orderdLibraryAccesses = [LibraryAccess.FullControl, LibraryAccess.ReadOnly, LibraryAccess.NoAccess];

export const prettyAccess = (access: LibraryAccess | string) => {
  if (access === LibraryAccess.FullControl) {
    return 'Full-control'
  }
  if (access === LibraryAccess.ReadOnly) {
    return 'Read-only'
  }
  if (access === LibraryAccess.NoAccess) {
    return 'No access'
  }
  return access.replace('_', '').toLowerCase();
}
;