import { faBullhorn, faP, faSquare, faSquareL, faThumbTack } from '@fortawesome/pro-regular-svg-icons';
import {  faSquareLeft, faSquareRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMedia } from 'react-media';
import { Loading } from '../chrome/Loading';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { GRID_MEDIA_QUERIES } from '../media';
import logo from '../svg/songlib-logo.svg';
import cs from 'classnames';
import React from 'react';

interface Props {
  showHowToPush: boolean
  liveSyncEnabled: boolean
  muted: boolean
}





export const GettingStarted = (props: Props) => {
  const gteLg = useMedia({query: GRID_MEDIA_QUERIES.lgGTE});
  return (
    <Loading>
      <div className={cs(props.muted ? 'text-muted' : '', 'text-start text-md-center')} style={{transitionProperty: 'color', transitionDelay: '0.3s'}}>
        <div style={{maxWidth: '100px'}} className='mx-0 mx-md-auto mb-3'>
          <img src={logo} className='img-fluid' style={{filter: 'grayscale(1) brightness(70%)'}}/>
        </div>

        <table className='mx-md-auto d-none d-sm-table'>


          <TipTr text='Previous or next song'>
            <FontAwesomeIcon icon={faSquareLeft} size="2x" style={{color:'#ccc'}}/>
            &nbsp;
            <FontAwesomeIcon icon={faSquareRight} size="2x" style={{color:'#ccc'}}/>
          </TipTr>

          {gteLg &&
            <TipTr gap text='Keep song list always visible'>
              <TipButton><FontAwesomeIcon icon={faThumbTack}/></TipButton>
            </TipTr>
          }

          <TipTr gap text='Transpose or use a capo'>
            <TipButton dropdown>My Key</TipButton>
          </TipTr>

          <TipTr gap text={<>Auto&nbsp;change&nbsp;songs when&nbsp;a&nbsp;leader&nbsp;pushes</>}>
            <TipButton><ToggleSwitch flush value={props.liveSyncEnabled} onChange={() => {}}>Live Sync</ToggleSwitch></TipButton>
          </TipTr>

          {props.showHowToPush && <>
            <TipTr text='Push song to others'>
              <TipButton><FontAwesomeIcon icon={faBullhorn}/></TipButton>
            </TipTr>
            <TipTr text='Push slide to presenter'>
              <TipButton>(tap verse block)</TipButton>
            </TipTr>
          </>}

        </table>

      </div>
    </Loading>
  )
};


interface TipTrProps {
  text: React.ReactNode
  children: React.ReactNode
  gap?: boolean
}


const TipTr = (props: TipTrProps) => (
  <>
    <tr className=''>
      <td className={cs('pe-3 pb-2 text-start text-md-end', props.gap && 'pt-4')} style={{fontSize: '16px'}}>
        <div>{props.text}</div>
        <div className='d-md-none'>{props.children}</div>
        {/* <button className={cs('btn-sm disabled btn text-dark border-0 d-md-none', props.dropdown && 'dropdown-toggle')} style={{background: '#ddd'}}>{props.children}</button> */}
      </td>
      <td className={cs('pe-3 pb-2 d-none d-md-table-cell', props.gap && 'pt-4')} style={{textAlign: 'left'}}>
        <div className=''>{props.children}</div>
        {/* <button className={cs('btn-sm disabled btn text-dark border-0', props.dropdown && 'dropdown-toggle')} style={{background: '#ddd'}}>{props.children}</button> */}
      </td>
    </tr>
    {/* <tr className='d-lg-none'>
      <td className={cs('pe-3 pb-2', props.gap && 'pt-4')} style={{fontSize: '16px', textAlign: 'center'}}>
        {props.text}<br/>
        <button className={cs('btn-sm disabled btn text-dark border-0', props.dropdown && 'dropdown-toggle')} style={{background: '#ddd'}}>{props.children}</button>
      </td>
    </tr> */}
  </>
)


const TipButton = (props: {dropdown?: boolean, children: React.ReactNode}) => (
  <button 
    className={cs('disabled btn text-dark border-0', props.dropdown && 'dropdown-toggle')} 
    style={{background: '#ddd'}}
  >
    {props.children}
  </button>
)