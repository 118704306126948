import cs from 'classnames';
import { transposeChord } from '../transpose/transpose';
import { InstrumentToggleBtn } from './InstrumentToggleBtn';


interface Props {
  setlistKey: string | null
  type: 'capo' | 'semitones'
  semitones: number
  onChange: (semitones: number) => void
  onTypeChange: (type: 'capo' | 'semitones') => void
  className?: string
  children?: React.ReactNode
  emptyMsg?: React.ReactNode
}


export const CapoDropdown = (props: Props) => {
  const {semitones} = props
  
  return (

    <div className="dropdown">
      <button className={cs(props.className ?? "btn btn-light px-2 py-1 me-3", "dropdown-toggle")} type="button" data-bs-toggle="dropdown" aria-expanded="false">
        {props.children}
        <span className='d-none d-sm-inline'>
          { 
            semitones === 0
            ? (props.emptyMsg ?? 'Setlist')
            : <>
              {props.setlistKey && <span><b> {transposeChord(props.setlistKey, semitones)} </b></span>}
              ( 
                {(props.type === 'capo' 
                  ? `Capo ${semitonesToCapo(semitones)}` 
                  : <>{semitones < 0 ? '+' : ''}{0-semitones}&nbsp;Semitones</>
                )})
            </>
          }
        </span>
        <span className='d-sm-none'>
          { 
            semitones === 0
            ? props.setlistKey || 'Key'
            : <>
              {props.setlistKey && <span> {transposeChord(props.setlistKey, semitones)} </span>}
              <span className='d-none d-sm-inline'>( 
                {(props.type === 'capo' 
                  ? `Capo ${semitonesToCapo(semitones)}` 
                  : <>{semitones < 0 ? '+' : ''}{0-semitones}</>
                )}
              )</span>
            </>
          }
        </span>
      </button>
      <div className="dropdown-menu dropdown-menu-end">
        

        {props.type === 'capo' && [...new Array(12)].map((_, i) => 0-(i > 6 ? i - 12: i)).map(i => (
          <button 
              className={cs('dropdown-item d-flex', semitones === i && 'active')} 
              key={i}  
              onClick={() => props.onChange(i)}
          >
            {props.setlistKey &&
              <b style={{width: '50px'}}>{transposeChord(props.setlistKey, i)} </b>
            }
            <span className='ms-auto'>
              Capo&nbsp;{semitonesToCapo(i)}
            </span>
          </button>
        ))}

        {props.type === 'semitones' && [...new Array(12)].map((_, i) => (12-i)-6).map(i => (
          <button 
              className={cs('dropdown-item d-flex', semitones === i && 'active')} 
              key={i}  
              onClick={() => props.onChange(i)}
          >
            {props.setlistKey &&
              <b style={{width: '50px'}}>{transposeChord(props.setlistKey, i)} </b>
            }
            <span className='ms-auto'>
              {i < 0 ? '+' : ''}{0-i}&nbsp; Semitones
            </span>
          </button>
        ))}

        <div className='dropdown-divider'/>
        <div className='d-flex px-2 pb-0'>
          <InstrumentToggleBtn
            value={props.type}
            onChange={props.onTypeChange}
            w100
          />
        </div>
      </div>
    </div>
  );
};

const semitonesToCapo = (semitones: number) => (0 - semitones + 12) % 12;
