import { faBullhorn, faWavePulse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { CurrentSong_SetlistFragment, CurrentSong_SetlistSongFragment, MutationPushLiveArgs, usePushLiveMutation } from '../generated/graphql';


interface Props {
  buildSongLink: (setlistsong: number) => string
  setlistKey?: string
  showPush?: boolean
  setlist: Omit<CurrentSong_SetlistFragment, 'songs'>
  songs: readonly CurrentSong_SetlistSongFragment[]
  liveId?: number | null
  selectedId?: number | null
  flush?: boolean
  notes?: boolean
  onSelectSong?: () => void
  onPushSong?: () => void
}

export const LiveSyncSongList = (props: Props) => {
  const {setlist, songs} = props;
  // const c = useLiveSyncContext();
  
  const [, executePushLive] = usePushLiveMutation();
  const history = useHistory();

  return (
    <ul className={cs("list-group list-group-flush overflow-auto flex-fill", !props.flush && ' border-top border-bottom')}>
      {songs.map((s, i) => (
          <Link 
            to={props.buildSongLink(s.id)} 
            key={s.id} 
            onClick={props.onSelectSong}
            // data-bs-dismiss="offcanvas" 
            className={cs(
              "d-flex list-group-item list-group-item-action align-items-stretch p-0 position-relative", 
              props.liveId === s.id && 'text-success', 
              props.selectedId === s.id && 'bg-warning bg-opacity-25',
              i === songs.length - 1 && 'border-bottom'
            )}
          >
            {props.liveId === s.id &&
              <div className='position-absolute top-0 bottom-0 start-0 border-start border-success border-2'/>
            }
            <div className={cs('me-3 ps-3')}>
              <div className='my-2'>{i+1}.</div>
            </div>
            <div className='my-2 me-3 flex-fill'>
              <div /*className={props.selectedId === s.id ? 'fw-bold' : ''}*/>
                {s.title}

                {/* {props.liveId === s.id && <FontAwesomeIcon icon={faWavePulse} className='ms-2' title="The current live song"/>} */}
              </div>
              
              {props.notes && s.notes && <small className='fst-italic'>{s.notes}</small>}
            </div>

            {props.liveId === s.id && <div className='align-self-center'><span className="badge text-bg-success bg-opacity-100">Live</span></div>}

            <div className='my-2 px-3 ms-auto align-self-center'>
              {s.transpose.toKey}
            </div>

            <div className='border-start my-2'></div>

            {props.showPush &&
              <button className='btn text-primary rounded-0 py-2' onClick={e => {

                if (setlist.id) {
                  const vars: MutationPushLiveArgs = {
                    data: {
                      setlistId: setlist.id,
                      songId: s.song.id,
                    },
                    setlistKey: props.setlistKey ?? null,
                  };
                  executePushLive(vars);
                  history.push(props.buildSongLink(s.id));
                  if (props.onPushSong) {
                    props.onPushSong()
                  }
                }
                e.preventDefault()
              }}>
                <FontAwesomeIcon icon={faBullhorn}/>
              </button>
            }
          </Link>

      ))}
    </ul>
  );
};
